@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Inter";
  src: url("../../fonts/Inter-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Inter";
  src: url("../../fonts/Inter-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Inter";
  src: url("../../fonts/Inter-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto Mono";
  src: url("../../fonts/RobotoMono-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto Mono";
  src: url("../../fonts/RobotoMono-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@layer base {
  html {
    @apply overflow-hidden;
  }
}

@layer components {
  .inter-5xlarge-regular {
    @apply text-5xlarge leading-4xlarge font-sans font-normal;
  }
  .inter-5xlarge-semibold {
    @apply text-5xlarge leading-4xlarge font-sans font-semibold;
  }

  .inter-4xlarge-regular {
    @apply text-4xlarge leading-3xlarge font-sans font-normal;
  }
  .inter-4xlarge-semibold {
    @apply text-4xlarge leading-3xlarge font-sans font-semibold;
  }

  .inter-3xlarge-regular {
    @apply text-3xlarge leading-2xlarge font-sans font-normal;
  }
  .inter-3xlarge-semibold {
    @apply text-3xlarge leading-2xlarge font-sans font-semibold;
  }

  .inter-2xlarge-regular {
    @apply text-2xlarge leading-xlarge font-sans font-normal;
  }
  .inter-2xlarge-semibold {
    @apply text-2xlarge leading-xlarge font-sans font-semibold;
  }

  .inter-xlarge-regular {
    @apply text-xlarge leading-large font-sans font-normal;
  }
  .inter-xlarge-semibold {
    @apply text-xlarge leading-large font-sans font-semibold;
  }

  .inter-large-regular {
    @apply text-large leading-base font-sans font-normal;
  }
  .inter-large-semibold {
    @apply text-large leading-base font-sans font-semibold;
  }

  .inter-base-regular {
    @apply leading-base font-sans text-base font-normal;
  }
  .inter-base-semibold {
    @apply leading-base font-sans text-base font-semibold;
  }

  .inter-small-regular {
    @apply text-small leading-small font-sans font-normal;
  }
  .inter-small-semibold {
    @apply text-small leading-small font-sans font-semibold;
  }

  .inter-xsmall-regular {
    @apply text-xsmall leading-xsmall font-sans font-normal;
  }
  .inter-xsmall-semibold {
    @apply text-xsmall leading-xsmall font-sans font-semibold;
  }

  .mono-5xlarge-regular {
    @apply text-5xlarge leading-4xlarge font-mono font-normal;
  }
  .mono-5xlarge-semibold {
    @apply text-5xlarge leading-4xlarge font-mono font-bold;
  }

  .mono-4xlarge-regular {
    @apply text-4xlarge leading-3xlarge font-mono font-normal;
  }
  .mono-4xlarge-semibold {
    @apply text-4xlarge leading-3xlarge font-mono font-bold;
  }

  .mono-3xlarge-regular {
    @apply text-3xlarge leading-2xlarge font-mono font-normal;
  }
  .mono-3xlarge-semibold {
    @apply text-3xlarge leading-2xlarge font-mono font-bold;
  }

  .mono-2xlarge-regular {
    @apply text-2xlarge leading-xlarge font-mono font-normal;
  }
  .mono-2xlarge-semibold {
    @apply text-2xlarge leading-xlarge font-mono font-bold;
  }

  .mono-xlarge-regular {
    @apply text-xlarge leading-large font-mono font-normal;
  }
  .mono-xlarge-semibold {
    @apply text-xlarge leading-large font-mono font-bold;
  }

  .mono-large-regular {
    @apply text-large leading-base font-mono font-normal;
  }
  .mono-large-semibold {
    @apply text-large leading-base font-mono font-bold;
  }

  .mono-base-regular {
    @apply leading-base font-mono text-base font-normal;
  }
  .mono-base-semibold {
    @apply leading-base font-mono text-base font-bold;
  }

  .mono-small-regular {
    @apply text-small leading-small font-mono font-normal;
  }
  .mono-small-semibold {
    @apply text-small leading-small font-mono font-bold;
  }

  .mono-xsmall-regular {
    @apply text-xsmall leading-xsmall font-mono font-normal;
  }
  .mono-xsmall-semibold {
    @apply text-xsmall leading-xsmall font-mono font-bold;
  }

  .radio-outer-ring > span.indicator[data-state="checked"] {
    @apply rounded-circle shadow-violet-60 shadow-[0_0_0_2px];
  }

  .bold-active-item + span {
    @apply inter-base-semibold;
  }
}

@layer components {
  .react-select-container {
    @apply -mx-3 mb-1 h-6 cursor-text border-0 p-0;

    .react-select__control {
      @apply border-0 bg-inherit shadow-none;
    }

    .react-select__control,
    .react-select__control--is-focused,
    .react-select__control--menu-is-open {
      @apply m-0 h-6 p-0 !important;
    }

    .react-select__value-container--is-multi,
    .react-select__value-container--has-value {
      @apply m-0 h-6 p-0 pl-3 !important;
    }

    .react-select__menu,
    .react-select__menu-list {
      @apply z-[110] mt-0 rounded-t-none !important;
    }

    .react-select__value-container {
      @apply pl-3 pr-0;
    }

    .react-select__indicators {
      @apply flex h-full items-center p-0 pr-3;

      .react-select__indicator {
        @apply p-0;
      }
    }

    .react-select__input {
      @apply mt-0 w-full min-w-[120px] pt-0 !important;
    }

    .react-select__option,
    .react-select__option--is-focused,
    .react-select__option--is-selected {
      @apply bg-grey-0 hover:bg-grey-5 !important;
    }

    .react-select__multi-value,
    .react-select__input-container {
      @apply my-0 py-0;
    }
  }
}

@layer components {
  .badge {
    @apply rounded-rounded inter-small-semibold w-min py-0.5 px-2;
  }

  .badge-disabled {
    @apply bg-grey-50 text-grey-50 bg-opacity-10;
  }

  .badge-primary {
    @apply bg-violet-60 text-violet-60 bg-opacity-10;
  }

  .badge-danger {
    @apply bg-rose-50 bg-opacity-10 text-rose-50;
  }

  .badge-success {
    @apply bg-teal-50 bg-opacity-10 text-teal-50;
  }

  .badge-warning {
    @apply bg-yellow-40 text-yellow-60 bg-opacity-20;
  }

  .badge-ghost {
    @apply text-grey-90 border-grey-20 whitespace-nowrap border;
  }

  .badge-default {
    @apply bg-grey-10 text-grey-90;
  }

  .badge-medium {
    @apply rounded-rounded inter-small-semibold px-3 py-1.5;
  }

  .badge-large {
    @apply px-base py-xsmall rounded-rounded inter-base-semibold;
  }

  .btn {
    @apply rounded-rounded focus:shadow-cta flex items-center justify-center focus:outline-none;
  }

  .btn-large {
    @apply inter-base-semibold px-large py-small;
  }

  .btn-medium {
    @apply inter-base-semibold px-base py-[7px];
  }

  .btn-small {
    @apply inter-small-semibold px-small py-[5px];
  }

  .btn-xsmall {
    @apply inter-small-semibold px-xsmall py-[1px];
  }

  .btn-primary {
    @apply bg-violet-60 text-grey-0 active:bg-violet-70 disabled:bg-grey-20  disabled:text-grey-40 hover:bg-violet-50;
  }

  .btn-secondary {
    @apply bg-grey-0 text-grey-90 border-grey-20 hover:bg-grey-5 active:bg-grey-5 active:text-violet-60 focus:border-violet-60  disabled:bg-grey-0 disabled:text-grey-30 border;
  }

  .btn-danger {
    @apply bg-grey-0 border-grey-20 hover:bg-grey-10 active:bg-grey-20 disabled:bg-grey-0 disabled:text-grey-30 border text-rose-50;
  }

  .btn-nuclear {
    @apply text-grey-0 hover:bg-rose-40 active:bg-rose-60 disabled:bg-grey-20 disabled:text-grey-40 bg-rose-50;
  }

  .btn-ghost {
    @apply text-grey-90 hover:bg-grey-5 active:bg-grey-5 active:text-violet-60 focus:border-violet-60  disabled:text-grey-30 bg-transparent disabled:bg-transparent;
  }

  .btn-primary-large {
    @apply btn btn-large btn-primary;
  }
  .btn-primary-medium {
    @apply btn btn-medium btn-primary;
  }
  .btn-primary-small {
    @apply btn btn-small btn-primary;
  }
  .btn-primary-xsmall {
    @apply btn btn-xsmall btn-primary;
  }
  .btn-secondary-large {
    @apply btn btn-large btn-seconday;
  }
  .btn-secondary-medium {
    @apply btn btn-medium btn-seconday;
  }
  .btn-secondary-small {
    @apply btn btn-small btn-seconday;
  }
  .btn-secondary-xsmall {
    @apply btn btn-xsmall btn-seconday;
  }
  .btn-ghost-large {
    @apply btn btn-large btn-ghost;
  }
  .btn-ghost-medium {
    @apply btn btn-medium btn-ghost;
  }
  .btn-ghost-small {
    @apply btn btn-small btn-ghost;
  }
  .btn-ghost-xsmall {
    @apply btn btn-xsmall btn-ghost;
  }
}

@layer components {
  .alert .alert__content a {
    @apply underline;
  }

  .alert--primary {
    @apply bg-violet-5 border-l-violet-20 border-l-8;
  }

  .alert--primary .alert__icon {
    @apply text-violet-40;
  }

  .alert--primary .alert__title {
    @apply text-violet-80;
  }

  .alert--primary .alert__content {
    @apply text-violet-70;
  }

  .alert--primary .alert__content a {
    @apply text-violet-80;
  }

  .alert--primary .alert__content a:hover {
    @apply text-violet-50;
  }

  .alert--primary .alert__action,
  .alert--primary .alert__dismiss {
    @apply bg-violet-5 text-violet-80;
  }

  .alert--primary .alert__action:hover,
  .alert--primary .alert__dismiss:hover {
    @apply bg-violet-10;
  }

  .alert--primary .alert__action:focus,
  .alert--primary .alert__dismiss:focus {
    @apply ring-violet-40 ring-offset-violet-30;
  }

  .alert--danger {
    @apply bg-rose-5 border-l-rose-20 border-l-8;
  }

  .alert--danger .alert__icon {
    @apply text-rose-40;
  }

  .alert--danger .alert__title {
    @apply text-rose-80;
  }

  .alert--danger .alert__content {
    @apply text-rose-70;
  }

  .alert--danger .alert__content a {
    @apply text-rose-80;
  }

  .alert--danger .alert__content a:hover {
    @apply text-rose-50;
  }

  .alert--danger .alert__action,
  .alert--danger .alert__dismiss {
    @apply bg-rose-5 text-rose-80;
  }

  .alert--danger .alert__action:hover,
  .alert--danger .alert__dismiss:hover {
    @apply bg-rose-10;
  }

  .alert--danger .alert__action:focus,
  .alert--danger .alert__dismiss:focus {
    @apply ring-rose-40 ring-offset-rose-30;
  }

  .alert--success {
    @apply bg-teal-5 border-l-teal-20 border-l-8;
  }

  .alert--success .alert__icon {
    @apply text-teal-40;
  }

  .alert--success .alert__title {
    @apply text-teal-80;
  }

  .alert--success .alert__content {
    @apply text-teal-70;
  }

  .alert--success .alert__content a {
    @apply text-teal-80;
  }

  .alert--success .alert__content a:hover {
    @apply text-teal-60;
  }

  .alert--success .alert__action,
  .alert--success .alert__dismiss {
    @apply bg-teal-5 text-teal-80;
  }

  .alert--success .alert__action:hover,
  .alert--success .alert__dismiss:hover {
    @apply bg-teal-10;
  }

  .alert--success .alert__action:focus,
  .alert--success .alert__dismiss:focus {
    @apply ring-teal-40 ring-offset-teal-30;
  }

  .alert--warning {
    @apply bg-yellow-5 border-l-yellow-20 border-l-8;
  }

  .alert--warning .alert__icon {
    @apply text-yellow-40;
  }

  .alert--warning .alert__title {
    @apply text-yellow-80;
  }

  .alert--warning .alert__content {
    @apply text-yellow-70;
  }

  .alert--warning .alert__content a {
    @apply text-yellow-80;
  }

  .alert--warning .alert__content a:hover {
    @apply text-yellow-60;
  }

  .alert--warning .alert__action,
  .alert--warning .alert__dismiss {
    @apply bg-yellow-5 text-yellow-80;
  }

  .alert--warning .alert__action:hover,
  .alert--warning .alert__dismiss:hover {
    @apply bg-yellow-10;
  }

  .alert--warning .alert__action:focus,
  .alert--warning .alert__dismis:focus {
    @apply ring-yellow-40 ring-offset-yellow-30;
  }

  .alert--default {
    @apply bg-grey-5 border-l-grey-20 border-l-8;
  }

  .alert--default .alert__icon {
    @apply text-grey-40;
  }

  .alert--default .alert__title {
    @apply text-grey-80;
  }

  .alert--default .alert__content {
    @apply text-grey-70;
  }

  .alert--default .alert__content a {
    @apply text-grey-80;
  }

  .alert--default .alert__content a:hover {
    @apply text-grey-50;
  }

  .alert--default .alert__action,
  .alert--default .alert__dismiss {
    @apply bg-grey-5 text-grey-80;
  }

  .alert--default .alert__action:hover,
  .alert--default .alert__dismiss:hover {
    @apply bg-grey-10;
  }

  .alert--default .alert__action:focus,
  .alert--default .alert__dismiss:focus {
    @apply ring-grey-40 ring-offset-grey-30;
  }
}

@layer components {
  .date-picker {
    @apply border-0 pt-6 outline-none !important;

    .react-datepicker__month-container {
      .react-datepicker__header {
        @apply border-0 bg-inherit;
      }
    }

    .react-datepicker__day-names {
      @apply inter-base-semibold pt-4;

      .react-datepicker__day-name {
        @apply m-0 w-[40px];
      }
    }

    .react-datepicker__month {
      @apply m-0;
    }
    .react-datepicker__day {
      @apply inter-base-regular;
    }
    .react-datepicker__day--today {
      @apply text-grey-90 inter-base-semibold bg-grey-10 rounded !important;
    }

    .react-datepicker__day--outside-month,
    .past {
      @apply text-grey-40 !important;
    }

    .date {
      @apply text-grey-90 relative m-[0px] h-[38px] w-[38px] pt-3 align-middle leading-none;
      :hover {
        @apply cursor-pointer;
      }
    }
    .chosen,
    .react-datepicker__day--keyboard-selected {
      @apply bg-violet-60 text-grey-0 inter-base-semibold leading-none !important;
    }
  }

  .time-list::-webkit-scrollbar {
    /* chrome */
    display: none;
  }

  .time-list {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .vice-city {
    @apply from-vice-start to-vice-stop bg-gradient-to-tr;
  }

  .hidden-actions[data-state="open"] {
    opacity: 1;
  }

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  ::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }

  ::-webkit-scrollbar-track {
    @apply bg-transparent;
  }

  ::-webkit-scrollbar-thumb {
    @apply rounded-rounded bg-grey-30;
  }

  ::-webkit-scrollbar-thumb:hover {
    @apply bg-grey-40;
  }

  /* TODO: Fix this as it breaks builds when using preset */
  /* .accordion-margin-transition {
    @apply transition-[margin] duration-300 ease-[cubic-bezier(0.87,0,0.13,1)];
  } */

  .col-tree:last-child .bottom-half-dash {
    @apply border-none;
  }
}

.scrolling-touch {
  -webkit-overflow-scrolling: touch;
}
.scrolling-auto {
  -webkit-overflow-scrolling: auto;
}

/* Classes to remove number spinners from inputs of type number */
/* Chrome, Safari, Edge, Opera */
.remove-number-spinner::-webkit-outer-spin-button,
.remove-number-spinner::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.remove-number-spinner {
  -moz-appearance: textfield;
}
